@tailwind base;

@tailwind components;

@tailwind utilities;

@layer base {
    :root {
        --primary: #a6b3cd;
        --primary-light: #a6b3cd;
        --primary-lighter: #a6b3cd;
        --primary-lightest: #a6b3cd;
        --primary-dark: #a6b3cd;
        --primary-darker: #a6b3cd;
        --primary-darkest: #a6b3cd;

        --secondary: #14121F;
        --secondary-light: #14121F;
        --secondary-lighter: #14121F;
        --secondary-lightest: #14121F;
        --secondary-dark: #14121F;
        --secondary-darker: #14121F;
        --secondary-darkest: #14121F;

        --text: #14121F;
        --accent: #9CABC7;
        --light: #F6F7FB;
        --dark: #14121F;
        --grey: #F2F3F8;
        --silver: #FBFCFF;
        --danger: #fe3562;
    }

    * {outline: 2px solid transparent !important; outline-offset: 2px !important;}
    body.no-scroll {overflow:hidden;}

    html,body {height: 100%; color: var(--text); font-family: 'Poppins', sans-serif; font-size: 14px;}
    body {
        background: var(--light);
    }
    .input-label {
        @apply pb-2 block
    }
    [multiple],
    [type=date],
    [type=datetime-local],
    [type=email],
    [type=month],
    [type=number],
    [type=password],
    [type=search],
    [type=tel],
    [type=text],
    [type=time],
    [type=url],
    [type=week],
    select,
    textarea,
    .input-box {
        @apply block text-gray-800 w-full rounded-xl border border-gray-100 focus:border-primary-500 focus:ring focus:ring-primary-dark focus:ring-opacity-100 py-3 px-5;
    }
    [multiple]:placeholder,
    [type=date]:placeholder,
    [type=datetime-local]:placeholder,
    [type=email]:placeholder,
    [type=month]:placeholder,
    [type=number]:placeholder,
    [type=password]:placeholder,
    [type=search]:placeholder,
    [type=tel]:placeholder,
    [type=text]:placeholder,
    [type=time]:placeholder,
    [type=url]:placeholder,
    [type=week]:placeholder,
    select:placeholder,
    textarea:placeholder {
        @apply text-gray-200
    }
    .input-sm {
        @apply p-2 text-sm
    }
    .input-xs {
        @apply py-1 px-2 text-xs
    }
    [multiple].error,
    [type=date].error,
    [type=datetime-local].error,
    [type=email].error,
    [type=month].error,
    [type=number].error,
    [type=password].error,
    [type=search].error,
    [type=tel].error,
    [type=text].error,
    [type=time].error,
    [type=url].error,
    [type=week].error,
    select.error,
    textarea.error {
        @apply border-red-400 text-red-500 ring ring-red-200 ring-opacity-100;
    }
    input[type="checkbox"] {
        @apply w-5 h-5 rounded border border-gray-200;
    }
    input[type="radio"] {
        @apply w-5 h-5 border border-gray-200;
    }
    .input-checkbox input[type="checkbox"],
    .input-radio input[type="radio"] {
        @apply align-middle focus:border-primary-100 focus:ring focus:ring-primary focus:ring-opacity-100 ring-offset-0 text-primary
    }

    .input-checkbox input[type="checkbox"]:checked,
    .input-radio input[type="radio"]:checked {
        @apply border-primary-100 bg-primary
    }
    .input-checkbox > span,
    .input-radio > span {
        @apply ml-3 inline-block align-middle
    }
    .input-box {
        @apply relative pl-12
    }
    .input-box input {
        @apply absolute opacity-0
    }
    .input-box > span:before,
    .input-box > span:after  {
        content: '';
        @apply absolute left-3 w-6 h-6 border border-gray-200 rounded;
    }
    .input-box > input[type="radio"] + span:before,
    .input-box > input[type="radio"] + span:after {
        @apply rounded-full;
    }
    .input-box input:checked + span:before {
        @apply bg-primary border-primary;
    }
    .input-box input[type="checkbox"]:checked + span:after {
        content: "\f00c"; font-family: 'Font Awesome 5 Free'; font-weight: 900;
        @apply text-white text-center border-transparent text-sm leading-6;
    }
    .input-box input[type="radio"]:checked + span:after {
        @apply w-2 h-2 rounded-full bg-white top-5 left-5;
    }
    .input-holder {
        @apply relative;
    }
    .input-holder--icon input {
        @apply pl-14;
    }
    .input-holder--icon .input-holder-icon {
        @apply h-12 w-12 left-1 top-0 leading-10 p-1 pt-1.5 text-xl absolute text-center text-gray-400;
    }
    input[type="submit"],
    button[type="submit"],
    .btn {
        @apply inline-block max-w-full rounded-xl px-8 py-4 border-0 text-white font-medium text-center cursor-pointer bg-primary hover:bg-primary-dark transition cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed;
    }
    .btn--md {
        @apply px-7 w-auto py-3 rounded-lg text-sm
    }
    .btn--sm {
        @apply px-4 w-auto py-2 rounded-lg text-sm
    }
    .btn--icon {
        @apply w-12 h-12 p-1 leading-10 text-center cursor-pointer;
    }
    .btn.btn--icon i:first-child {
        @apply mr-0;
    }
    .btn--rounded {
        @apply rounded-2xl
    }
    .btn--circle {
        @apply rounded-full
    }
    .btn--secondary {
        @apply bg-secondary text-white hover:bg-secondary-dark disabled:opacity-50 disabled:cursor-not-allowed;
    }
    .btn--danger {
        @apply bg-danger text-white hover:bg-danger disabled:opacity-50 disabled:cursor-not-allowed;
    }
    .btn .button-icon {
        @apply mr-3
    }
    .btn--blue {
        @apply bg-blue-600 hover:bg-blue-700;
    }
    .btn--red {
        @apply bg-red-600 hover:bg-red-700;
    }
    .btn--gray {
        @apply bg-gray-100 hover:bg-gray-200 text-gray-700 group-hover:bg-gray-300
    }
    .btn--black {
        @apply bg-black text-white hover:bg-black text-white
    }
    .btn--light {
        @apply bg-primary-100 hover:bg-primary-300 text-light;
    }
    .btn--light-secondary {
        @apply bg-secondary-100 hover:bg-secondary-300 text-light;
    }
    .btn--light-blue {
        @apply bg-blue-100 text-blue-500 hover:bg-blue-200 hover:text-blue-600;
    }
    .btn--light-red {
        @apply bg-red-100 text-red-500 hover:bg-red-200 hover:text-red-600;
    }
    .btn--outline {
        @apply bg-transparent text-primary-500 border border-primary-500 hover:bg-transparent hover:text-primary hover:border-primary;
    }
    .btn--secondary.btn--outline {
        @apply bg-transparent text-secondary-500 border border-secondary-500 hover:bg-transparent hover:text-secondary hover:border-secondary;
    }
    .btn--blue.btn--outline {
        @apply bg-transparent text-blue-400 border border-blue-400 hover:bg-transparent hover:text-blue-600 hover:border-blue-600;
    }
    .btn--red.btn--outline {
        @apply bg-transparent text-red-400 border border-red-400 hover:bg-transparent hover:text-red-600 hover:border-red-600;
    }
    .btn--gray.btn--outline {
        @apply bg-transparent text-gray-400 border border-gray-400 hover:bg-transparent hover:text-gray-600 hover:border-gray-600;
    }
    .btn--text {
        @apply bg-transparent text-primary-500 hover:bg-transparent hover:text-primary hover:border-primary;
    }
    .btn--secondary.btn--text {
        @apply text-secondary-500;
    }
    .btn--gray.btn--text {
        @apply text-gray-400;
    }
    .btn--blue.btn--text {
        @apply text-blue-400;
    }
    .btn--red.btn--text {
        @apply text-red-400;
    }
    .btn i:first-child {
        @apply mr-2
    }
    .btn--nowrap {
        @apply whitespace-nowrap;
    }

    .text-xxs {font-size: 0.675rem; line-height: 1rem;}
    .text-0 {font-size: 0; line-height: 0;}

    .switch {
        @apply relative w-14 h-8 inline-block;
    }
    .switch input {
        @apply opacity-0
    }
    .switch-slider {
        transition: 333ms;
        @apply absolute left-0 top-0 right-0 bottom-0 cursor-pointer bg-gray-300 rounded-full;
    }
    .switch-slider:before {
        content: '';
        transition: 333ms;
        @apply absolute h-6 w-6 rounded-full bg-white shadow top-1 left-1;
    }
    .switch input:checked + .switch-slider {
        @apply bg-secondary;
    }
    .switch input:checked + .switch-slider:before {
        @apply transform translate-x-6
    }
    .switch--primary .switch-slider {
        @apply bg-primary-100;
    }
    .switch--primary input:checked + .switch-slider {
        @apply bg-primary;
    }
    .switch--secondary .switch-slider {
        @apply bg-secondary-100;
    }
    .switch--secondary input:checked + .switch-slider {
        @apply bg-secondary;
    }
    .switch--red .switch-slider {
        @apply bg-red-100;
    }
    .switch--red input:checked + .switch-slider {
        @apply bg-red-600;
    }
}

h1 {
    @apply text-5xl;
}

h2 {
    @apply text-4xl;
}
h3 {
    @apply text-3xl;
}
h4 {
    @apply text-2xl;
}
h5 {
    @apply text-xl;
}
h6 {
    @apply text-lg;
}


.avatar {
    @apply rounded-full h-20 w-20 bg-cover
}
.avatar--sm {
    @apply h-12 w-12
}
.avatar--xl {
    @apply h-28 w-28
}
.avatar--rounded {
    @apply rounded-lg
}

.btn-icon {
    @apply w-8 h-8 rounded-full;
}
.icon-wrap {
    @apply w-10 h-10 leading-10 text-center rounded-lg;
}
.icon-wrap--lg {
    @apply w-12 h-12 leading-10 p-1 text-xl;
}
.icon-wrap--xl {
    @apply w-14 h-14 leading-10 p-2 text-2xl;
}
.page-heading h3 {
    @apply font-semibold mb-3;
}
.page-heading p {
    @apply text-gray-500;
}
.btn-icon {
    @apply h-12 w-12 leading-10 p-1.5 text-center rounded-full bg-secondary-500 text-white hover:bg-secondary transition;
}

.page-contents {
    @apply px-5 md:px-10;
}
.page-header {
    @apply py-5;
}
.filter-nav {
    @apply flex flex-wrap gap-4
}
.filter-nav-btn {
    @apply font-semibold text-lg text-gray-500 hover:text-black relative px-1 py-2;
}
.filter-nav-btn:after {
    content: ''; transition: width 333ms;
    @apply absolute left-0 right-0 bottom-0 bg-black h-0.5 w-0 mx-auto;
}
.filter-nav-btn.active {
    @apply text-black
}
.filter-nav-btn.active:after {
    @apply w-full;
}

.search-form {
    @apply relative w-full;
}
.search-form input[type="text"] {
    @apply bg-white rounded-full pl-5 py-3 leading-8 border-0 shadow-lg;
}
.search-form button {
    @apply absolute right-0 top-0 h-14 w-14 bg-transparent text-gray-400 text-lg
}

.filter-select {
    @apply relative max-w-full group;
}
.filter-select select { -webkit-appearance: none; -moz-appearance: none; appearance: none;
    @apply bg-white w-full rounded-full pl-5 py-3 leading-8 border-0 shadow-lg font-semibold text-lg text-gray-400;
}
.filter-select .filter-select-icon {
    @apply absolute right-0 top-0 h-14 w-14 leading-10 p-2 text-center bg-transparent text-gray-400 text-lg group-hover:text-gray-600 bg-white rounded-full pointer-events-none;
}

.card {
    @apply bg-white rounded-xl p-7 transition duration-500 ease-in-out group ;
}
.card-title {
    @apply font-semibold pb-3 overflow-hidden overflow-ellipsis md:overflow-auto;
}
.card-text {
    @apply text-gray-400 group-hover:text-white overflow-hidden overflow-ellipsis whitespace-nowrap w-full md:whitespace-normal;
}

.progress-bar,
.progress {
    @apply h-2 relative bg-gray-400 rounded;
}
.progress {
    @apply bg-red-500;
}

.page-card {
    @apply bg-white rounded-xl;
}
.page-card-top {
    @apply bg-primary-500 relative h-56 p-10 rounded-t-xl;
}
.page-card-content {
    @apply p-5 md:p-10 text-gray-400;
}
.page-card-content p {
    @apply leading-8 mb-6;
}
.profile-grid {
    @apply grid lg:grid-cols-4 md:grid-cols-2 text-gray-500 my-10;
}
.profile-grid > .col {
    @apply border lg:border-r-0 border-gray-100
}
.profile-grid > .col:first-child {
    @apply lg:rounded-l-2xl
}
.profile-grid > .col:last-child {
    @apply lg:rounded-r-2xl border-r
}
.page-title {
    @apply font-semibold text-gray-800;
}
.sub-title {
    @apply font-semibold text-gray-600 mb-5;
}

.pointer-list > li {
    @apply leading-10 relative;
}
.pointer-list > li:before {
    content: '';
}

.event-time {
    @apply text-2xl font-semibold text-gray-800;
}
.event-line {
    @apply h-14 w-1 rounded-full mx-3;
}
.calendar-events-group {
    @apply mb-10;
}
.calendar-events-group:last-child {
    @apply mb-0;
}
.calendar-events-item {
    @apply mb-8;
}
.calendar-events-item:last-child {
    @apply mb-0;
}
.dropdown-nav {
    @apply text-left origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-gray-600 ring-opacity-5 focus:outline-none
}
.dropdown-nav li button {
    @apply py-1 px-4 w-full hover:text-gray-600 text-left;
}
.transaction-table {
    @apply w-full
}
.transaction-table th,
.transaction-table td {
    @apply px-4 py-4 border-b border-gray-50
}
.transaction-table tr:last-child th,
.transaction-table tr:last-child td {
    @apply border-b-0
}
.transaction-table th {
    @apply font-normal text-gray-400
}
.transaction-table th:first-child,
.transaction-table td:first-child {
    @apply text-left pl-0
}
.transaction-table td {
    @apply font-semibold text-gray-600
}
.transaction-table td:last-child,
.transaction-table td:last-child {
    @apply text-left md:text-right pr-0;
}
.transaction-table tfoot tr:first-child th,
.transaction-table tfoot tr:first-child td {
    @apply border-t-2 border-gray-100 border-dashed
}
.alert-box {
    @apply p-4 rounded-lg bg-gray-100 text-gray-500 flex;
}
.alert-box-icon {
    @apply text-3xl w-12 h-12 text-gray-700 pr-4
}
.alert-box--info {
    @apply bg-blue-100 text-blue-600
}
.alert-box--info .alert-box-icon {
    @apply text-blue-600;
}
.alert-box--warning {
    @apply bg-yellow-100 text-yellow-600
}
.alert-box--warning .alert-box-icon {
    @apply text-yellow-600;
}
.alert-box--success {
    @apply bg-green-100 text-green-600
}
.alert-box--success .alert-box-icon {
    @apply text-green-600;
}
.alert-box--error {
    @apply bg-red-100 text-red-600
}
.alert-box--error .alert-box-icon {
    @apply text-red-600;
}

.table-wrapper {overflow-x: auto; overflow-y: hidden}
table.table {
    @apply w-full
}
table.table th,
table.table td {
    @apply px-4 py-4 border-b border-gray-50 text-left align-top;
}
table.table tr:last-child th,
table.table tr:last-child td {
    @apply border-b-0
}
table.table th {
    @apply font-semibold text-gray-400
}
table.table th:first-child,
table.table td:first-child {
    @apply text-left pl-0
}
table.table td {
    @apply align-middle font-semibold text-gray-600
}
table.table td:last-child,
table.table td:last-child {
    @apply text-center md:text-center pr-0;
}
table.table th:last-child,
table.table th:last-child {
    @apply text-center md:text-center pr-0;
}
table.table tfoot tr:first-child th,
table.table tfoot tr:first-child td {
    @apply border-t-2 border-gray-100 border-dashed
}

table.table-weekly-stats tr {
    @apply flex flex-wrap sm:table-row border border-gray-50 sm:border-none mb-10 sm:mb-0
}
table.table-weekly-stats thead tr {
    @apply hidden sm:table-row
}
table.table-weekly-stats td {
    @apply text-center block sm:table-cell px-0 w-1/2 sm:w-auto
}
table.table-weekly-stats td:nth-child(2n) {
    @apply border-r sm:border-r-0
}
table.table-weekly-stats td.col-day,
table.table-weekly-stats th.col-day {
    @apply text-center sm:text-left px-0 block sm:table-cell
}
table.table-weekly-stats th.col-day {
    @apply w-full sm:w-auto
}
.col-day-time {
    @apply pb-3 sm:hidden;
}

.action-bar {
    @apply fixed bottom-0 left-0 w-full bg-white lg:hidden py-2 px-4;
}
.action-bar ul {
    @apply flex gap-x-4
}
.action-bar li {
    @apply flex-1;
}
.action-bar li a {
    @apply block px-5 py-3 text-xl text-center rounded-lg hover:text-black transition;
}
.actionbar-spacer {
    @apply h-28 lg:hidden;
}

i.primary {
    color: #a6b3cd;
}
i.secondary {
    color: #14121F;
}
i.danger {
    color: #fe3562;
}
.stat-icon {
    @apply h-8 w-8 leading-8 text-center rounded-full bg-green-500 text-white transition text-sm inline-block;
}
.stat-icon--no {
    @apply bg-red-500;
}
.stat-icon--maybe {
    @apply bg-yellow-500;
}
.filter-button label span {
    @apply relative;
}
.filter-button .filter-btn {
    @apply relative py-1.5 px-4 rounded-md inline-block cursor-pointer hover:bg-gray-50 hover:shadow transition-all font-medium;
}
.filter-button .filter-btn.active {
    @apply bg-white shadow cursor-default
}
.filter-button label span {
    @apply relative py-1.5 px-4 rounded-md inline-block cursor-pointer hover:bg-gray-50 hover:shadow transition-all font-medium;
}
.filter-button label input[type="checkbox"] {
    @apply opacity-0 absolute left-0 top-0;
}
.filter-button label input[type="checkbox"]:checked ~ span {
    @apply bg-white shadow
}
@media(min-width:1024px) {
    .kanban-col {max-height: calc(100vh - 332px); min-height: initial; overflow-y: auto;}
}
input[type="checkbox"].notes-checkbox {
    @apply w-6 h-6 rounded-md;
}
input[type="checkbox"].notes-checkbox:focus { box-shadow: none;
    @apply ring-0 shadow-none;
}
